const WebMDIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10070_24505)">
        <rect width="24" height="24" rx="12" fill="white" />
        <g clipPath="url(#clip1_10070_24505)">
          <g clipPath="url(#clip2_10070_24505)">
            <path
              d="M11.1963 12C11.1963 12.4435 11.556 12.8033 11.9995 12.8033C12.4431 12.8033 12.8028 12.4435 12.8028 12C12.8028 11.5565 12.4431 11.1968 11.9995 11.1968C11.556 11.1968 11.1963 11.5565 11.1963 12Z"
              fill="#FF5236"
            />
            <path
              d="M8.38416 3.27129C8.65743 3.15801 8.97078 3.28786 9.08373 3.56113L10.4064 6.7539C10.5196 7.02717 10.3898 7.34052 10.1165 7.45347C9.84325 7.56676 9.5299 7.43691 9.41695 7.16364L8.09466 3.97086C7.98138 3.69759 8.11122 3.38425 8.38449 3.27129"
              fill="#938EE9"
            />
            <path
              d="M12.0005 3C12.2962 3 12.5361 3.23981 12.5361 3.53561V4.91355C12.5361 5.20934 12.2962 5.44916 12.0005 5.44916C11.7047 5.44916 11.4648 5.20934 11.4648 4.91355V3.53561C11.4648 3.23981 11.7047 3 12.0005 3Z"
              fill="#938EE9"
            />
            <path
              d="M12.0005 6.62207C12.2962 6.62207 12.5361 6.86188 12.5361 7.15768V8.53562C12.5361 8.83141 12.2962 9.07123 12.0005 9.07123C11.7047 9.07123 11.4648 8.83141 11.4648 8.53562V7.15768C11.4648 6.86188 11.7047 6.62207 12.0005 6.62207Z"
              fill="#938EE9"
            />
            <path
              d="M3.27128 8.38461C3.38456 8.11134 3.69758 7.9815 3.97085 8.09478L7.16363 9.41707C7.4369 9.53036 7.56674 9.84337 7.45346 10.1166C7.34017 10.3899 7.02716 10.5198 6.75389 10.4065L3.56111 9.08418C3.28784 8.9709 3.15833 8.65788 3.27128 8.38461Z"
              fill="#38ADF6"
            />
            <path
              d="M5.63625 5.63594C5.84526 5.42693 6.18444 5.42693 6.39345 5.63594L7.36795 6.61044C7.57729 6.81945 7.57729 7.15863 7.36795 7.36764C7.15894 7.57698 6.81975 7.57698 6.61074 7.36764L5.63625 6.39315C5.42724 6.18414 5.42724 5.84495 5.63625 5.63594Z"
              fill="#938EE9"
            />
            <path
              d="M8.19777 8.19741C8.40678 7.9884 8.74597 7.9884 8.95498 8.19741L9.92947 9.1719C10.1388 9.38091 10.1388 9.7201 9.92947 9.92911C9.72046 10.1384 9.38128 10.1384 9.17227 9.92911L8.19777 8.95461C7.98876 8.7456 7.98876 8.40642 8.19777 8.19741Z"
              fill="#938EE9"
            />
            <path
              d="M3.27142 15.6154C3.15813 15.3421 3.28798 15.0288 3.56125 14.9158L6.75403 13.5932C7.0273 13.4799 7.34064 13.6098 7.4536 13.883C7.56688 14.1563 7.43703 14.4696 7.16376 14.5826L3.97099 15.9049C3.69772 16.0182 3.38437 15.8883 3.27142 15.6151"
              fill="#38ADF6"
            />
            <path
              d="M3 12C3 11.7042 3.23981 11.4644 3.53561 11.4644H4.91355C5.20934 11.4644 5.44916 11.7042 5.44916 12C5.44916 12.2958 5.20934 12.5356 4.91355 12.5356H3.53561C3.23981 12.5356 3 12.2958 3 12Z"
              fill="#38ADF6"
            />
            <path
              d="M6.62207 12C6.62207 11.7042 6.86188 11.4644 7.15768 11.4644H8.53562C8.83141 11.4644 9.07123 11.7042 9.07123 12C9.07123 12.2958 8.83141 12.5356 8.53562 12.5356H7.15768C6.86188 12.5356 6.62207 12.2958 6.62207 12Z"
              fill="#38ADF6"
            />
            <path
              d="M8.38449 20.7287C8.11122 20.6155 7.98138 20.3024 8.09466 20.0292L9.41695 16.8364C9.53023 16.5631 9.84325 16.4333 10.1165 16.5466C10.3898 16.6598 10.5196 16.9729 10.4064 17.2461L9.08373 20.4389C8.97045 20.7122 8.65743 20.842 8.38416 20.7287"
              fill="#6CDFDF"
            />
            <path
              d="M5.63625 18.364C5.42724 18.155 5.42724 17.8159 5.63625 17.6068L6.61074 16.6323C6.81975 16.423 7.15894 16.423 7.36795 16.6323C7.57729 16.8414 7.57729 17.1805 7.36795 17.3896L6.39345 18.364C6.18444 18.5731 5.84526 18.5731 5.63625 18.364Z"
              fill="#6CDFDF"
            />
            <path
              d="M8.19777 15.8029C7.98876 15.5939 7.98876 15.2547 8.19777 15.0457L9.17227 14.0712C9.38128 13.8618 9.72046 13.8618 9.92947 14.0712C10.1388 14.2802 10.1388 14.6194 9.92947 14.8284L8.95498 15.8029C8.74597 16.0119 8.40678 16.0119 8.19777 15.8029Z"
              fill="#6CDFDF"
            />
            <path
              d="M15.6149 20.7287C15.3416 20.842 15.0283 20.7122 14.9153 20.4389L13.5927 17.2461C13.4794 16.9729 13.6093 16.6595 13.8825 16.5466C14.1558 16.4333 14.4692 16.5631 14.5821 16.8364L15.9044 20.0292C16.0177 20.3024 15.8878 20.6158 15.6146 20.7287"
              fill="#938EE9"
            />
            <path
              d="M12.0005 21C11.7047 21 11.4648 20.7602 11.4648 20.4644V19.0864C11.4648 18.7907 11.7047 18.5508 12.0005 18.5508C12.2962 18.5508 12.5361 18.7907 12.5361 19.0864V20.4644C12.5361 20.7602 12.2962 21 12.0005 21Z"
              fill="#6CDFDF"
            />
            <path
              d="M20.729 15.6154C20.6157 15.8887 20.3027 16.0185 20.0294 15.9053L16.8366 14.583C16.5634 14.4697 16.4335 14.1567 16.5468 13.8834C16.6601 13.6101 16.9731 13.4803 17.2464 13.5936L20.4392 14.9162C20.7124 15.0295 20.8423 15.3425 20.729 15.6158"
              fill="#38ADF6"
            />
            <path
              d="M18.3633 18.364C18.1543 18.5731 17.8151 18.5731 17.6061 18.364L16.6316 17.3896C16.4223 17.1805 16.4223 16.8414 16.6316 16.6323C16.8406 16.423 17.1798 16.423 17.3888 16.6323L18.3633 17.6068C18.5723 17.8159 18.5723 18.155 18.3633 18.364Z"
              fill="#938EE9"
            />
            <path
              d="M15.8028 15.803C15.5938 16.012 15.2546 16.012 15.0456 15.803L14.0711 14.8285C13.8617 14.6194 13.8617 14.2803 14.0711 14.0713C14.2801 13.8619 14.6193 13.8619 14.8283 14.0713L15.8028 15.0457C16.0118 15.2548 16.0118 15.5939 15.8028 15.803Z"
              fill="#938EE9"
            />
            <path
              d="M20.729 8.38461C20.8423 8.65788 20.7124 8.97123 20.4392 9.08418L17.2464 10.4065C16.9731 10.5198 16.6598 10.3899 16.5468 10.1166C16.4335 9.84337 16.5634 9.53002 16.8366 9.41707L20.0294 8.09478C20.3027 7.9815 20.616 8.11134 20.729 8.38461Z"
              fill="#38ADF6"
            />
            <path
              d="M20.9999 12C20.9999 12.2958 20.7601 12.5356 20.4643 12.5356H19.0864C18.7906 12.5356 18.5508 12.2958 18.5508 12C18.5508 11.7042 18.7906 11.4644 19.0864 11.4644H20.4643C20.7601 11.4644 20.9999 11.7042 20.9999 12Z"
              fill="#38ADF6"
            />
            <path
              d="M17.3779 12C17.3779 12.2958 17.1381 12.5356 16.8423 12.5356H15.4643C15.1685 12.5356 14.9287 12.2958 14.9287 12C14.9287 11.7042 15.1685 11.4644 15.4643 11.4644H16.8423C17.1381 11.4644 17.3779 11.7042 17.3779 12Z"
              fill="#38ADF6"
            />
            <path
              d="M15.6155 3.27129C15.8888 3.38458 16.0187 3.69759 15.9054 3.97086L14.5831 7.16364C14.4698 7.43691 14.1568 7.56676 13.8835 7.45347C13.6102 7.34019 13.4804 7.02717 13.5937 6.7539L14.9163 3.56113C15.0296 3.28786 15.3426 3.15801 15.6159 3.27129"
              fill="#6CDFDF"
            />
            <path
              d="M18.3633 5.63594C18.5723 5.84495 18.5723 6.18414 18.3633 6.39315L17.3888 7.36764C17.1798 7.57698 16.8406 7.57698 16.6316 7.36764C16.4223 7.15863 16.4223 6.81945 16.6316 6.61044L17.6061 5.63594C17.8151 5.42693 18.1543 5.42693 18.3633 5.63594Z"
              fill="#6CDFDF"
            />
            <path
              d="M15.8028 8.19741C16.0118 8.40642 16.0118 8.7456 15.8028 8.95461L14.8283 9.92911C14.6193 10.1384 14.2801 10.1384 14.0711 9.92911C13.8617 9.7201 13.8617 9.38091 14.0711 9.1719L15.0456 8.19741C15.2546 7.9884 15.5938 7.9884 15.8028 8.19741Z"
              fill="#6CDFDF"
            />
            <path
              d="M12.0005 13.8328C12.2962 13.8328 12.5361 14.0726 12.5361 14.3684V16.8129C12.5361 17.1087 12.2962 17.3485 12.0005 17.3485C11.7047 17.3485 11.4648 17.1087 11.4648 16.8129V14.3684C11.4648 14.0726 11.7047 13.8328 12.0005 13.8328Z"
              fill="#6CDFDF"
            />
          </g>
        </g>
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#C2BFBA" />
      <defs>
        <clipPath id="clip0_10070_24505">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_10070_24505">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip2_10070_24505">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WebMDIcon;
