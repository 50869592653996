/**
 * @fileoverview gRPC error codes
 * Constant values for gRPC error codes to be used with error handling.
 * Codes taken from https://grpc.github.io/grpc/core/md_doc_statuscodes.html
 */

enum GRPC_ERROR_CODES {
  OK = 0,
  CANCELLED,
  UNKNOWN,
  INVALID_ARGUMENT,
  DEADLINE_EXCEEDED,
  NOT_FOUND,
  ALREADY_EXISTS,
  PERMISSION_DENIED,
  RESOURCE_EXHAUSTED,
  FAILED_PRECONDITION,
  ABORTED,
  OUT_OF_RANGE,
  UNIMPLEMENTED,
  INTERNAL,
  UNAVAILABLE,
  DATA_LOSS,
  UNAUTHENTICATED,
}

export default GRPC_ERROR_CODES;
