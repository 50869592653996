import {Box} from '@mui/material';
import {useEffect} from 'react';
import {AsyncAssistantLoader} from '../../components/AsyncAssistantLoader/AsyncAssistantLoader';

function MobileAssistantPage() {
  useEffect(() => {
    document.body.style.backgroundColor = '#FFFFFF';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <Box data-testid="assistant-box">
      {<AsyncAssistantLoader isMobileEmbed />}
    </Box>
  );
}

export default MobileAssistantPage;
