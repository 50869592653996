/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/card_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CardService } from "./card_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListTodoCardsResponse } from "./card_service";
import type { ListTodoCardsRequest } from "./card_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * //////////////////////// Potential Errors //////////////////////////
 * ListTodoCards
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: participant enrollment language cannot be parsed
 * Error: codes.FailedPrecondition
 * Scenario: task cards can not be properly made(cannot parse task card name, task card fields wrongly populated, etc)
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.CardService
 */
export interface ICardServiceClient {
    /**
     * @generated from protobuf rpc: ListTodoCards(verily_me.web.home.bff.api.v1.ListTodoCardsRequest) returns (verily_me.web.home.bff.api.v1.ListTodoCardsResponse);
     */
    listTodoCards(input: ListTodoCardsRequest, options?: RpcOptions): UnaryCall<ListTodoCardsRequest, ListTodoCardsResponse>;
}
/**
 * //////////////////////// Potential Errors //////////////////////////
 * ListTodoCards
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: participant enrollment language cannot be parsed
 * Error: codes.FailedPrecondition
 * Scenario: task cards can not be properly made(cannot parse task card name, task card fields wrongly populated, etc)
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.CardService
 */
export class CardServiceClient implements ICardServiceClient, ServiceInfo {
    typeName = CardService.typeName;
    methods = CardService.methods;
    options = CardService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListTodoCards(verily_me.web.home.bff.api.v1.ListTodoCardsRequest) returns (verily_me.web.home.bff.api.v1.ListTodoCardsResponse);
     */
    listTodoCards(input: ListTodoCardsRequest, options?: RpcOptions): UnaryCall<ListTodoCardsRequest, ListTodoCardsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListTodoCardsRequest, ListTodoCardsResponse>("unary", this._transport, method, opt, input);
    }
}
