import {useEffect, useRef} from 'react';

/**
 * useInterval
 * A React hook that provides a setInterval functionality tied to the component lifecycle.
 *
 * @param callback - The function to execute at each interval.
 * @param delay - The time (in ms) between function executions. Pass null to pause the interval.
 */
const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay]);
};

export default useInterval;
