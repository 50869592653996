import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

// TODO: When feature flag is removed, remove this
// class and replace with `import {Assistant} from '@verily-src/assistant-mfe';`
export function AsyncAssistantLoader({
  isMobileEmbed = false,
}: {
  isMobileEmbed?: boolean;
}) {
  const [assistant, setAssistant] = useState<JSX.Element | null>(null);

  // TODO: Delete feature flag after merged to production
  const assistantEnabled = config.getBoolean('FEATURE_ASSISTANT_ENABLED');

  useEffect(() => {
    async function loadAssistant() {
      const module = await System.import('@verily-src/assistant-mfe');
      setAssistant(<module.Assistant isMobileEmbed={isMobileEmbed} />);
    }

    if (assistantEnabled) {
      loadAssistant();
    }
  }, []);

  if (!assistant) {
    return null;
  }

  return assistant;
}
