import {Box, Typography, useTheme} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorImage from './ErrorImage';

const PageNotFound = () => {
  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('not-found-page-header')}`;
  });

  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
      data-testid="error-box"
    >
      <Box
        sx={{
          p: 7,
        }}
      >
        {ErrorImage}
      </Box>
      <Typography variant="display5" component="h2" sx={{mb: 2}}>
        {t('sorry-we-couldnt-find-that-page')}
      </Typography>
      <Typography variant="body1" color={theme.palette.text.muted} sx={{mb: 3}}>
        {t('the-link-has-expired-or-the-page-no-longer-exists')}
      </Typography>
      <Button href="/me/home">{t('go-to-home')}</Button>
    </Box>
  );
};

export default PageNotFound;
