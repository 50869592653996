import GRPC_ERROR_CODES from './ErrorCodes';

/**
 * @fileoverview gRPC error handling
 * Takes in an error from a catch statement.
 * Determines if the error is not a gRPC error first and escapes out early if taht is the case.
 * Otherwise, it determines the type of gRPC error and handles it accordingly.
 */

export declare interface GrpcError {
  readonly code?: number;
  readonly message?: string;
}

export function isGrpcError(err: unknown): err is GrpcError {
  return (
    typeof (err as GrpcError).code === 'number' &&
    typeof (err as GrpcError).message === 'string'
  );
}

function errorLogText(err: Error, grpcStatusCode: number) {
  return `grpc status with code: ${grpcStatusCode} - ${GRPC_ERROR_CODES[grpcStatusCode]} \n${err}`;
}

export function handleGrpcError(err: unknown) {
  // Verify error is grpc error.
  if (!isGrpcError(err)) {
    console.error(err);
    return err;
  }

  const grpcStatusCode = err.code;
  const grpcMessage = err.message;
  const grpcError: Error = err as Error;

  switch (grpcStatusCode) {
    case GRPC_ERROR_CODES.UNAUTHENTICATED:
      // Redirect to log in page?
      console.error(errorLogText(grpcError, grpcStatusCode!));
      break;
    case GRPC_ERROR_CODES.INVALID_ARGUMENT:
      // Log a message about the invalid argument.
      console.error(errorLogText(grpcError, grpcStatusCode!));
      break;
    case GRPC_ERROR_CODES.FAILED_PRECONDITION:
      // Log a message about the failed precondition.
      console.error(errorLogText(grpcError, grpcStatusCode!));
      break;
    case GRPC_ERROR_CODES.NOT_FOUND:
      // Log a message about the not found error.
      console.error(errorLogText(grpcError, grpcStatusCode!));
      break;
    default:
      // Log a message about the uncaught error type.
      console.error(`undefined ${errorLogText(grpcError, grpcStatusCode!)}`);
  }
  const newError = new Error(
    `code: ${grpcStatusCode}, message: ${grpcMessage}`,
    {
      cause: grpcError,
    }
  );
  return newError;
}
