import './publicPath';
// Need to hoist public path setting to top so ES6 imports do not come before it.
import {
  DEFAULT_LOCALES,
  init as initL10n,
  PSEUDO_LOCALE,
} from '@verily-src/l10n/src/l10n';

import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';
import logError from './utils/logError';

initL10n(
  f => require(`../../l10n/locales/${f}`),
  process.env.INCLUDE_PSEUDO_LOCALE
    ? [...DEFAULT_LOCALES, PSEUDO_LOCALE]
    : DEFAULT_LOCALES
);

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err) {
    // Log any uncaught exceptions here
    logError(err);
    return <></>;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
