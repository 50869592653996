import {CssBaseline, ThemeProvider} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';
import useLocale from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useLocale';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import DiscoverPage from '../../pages/DiscoverPage/DiscoverPage';
import HomePage from '../../pages/HomePage/HomePage';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import MobileAssistantPage from '../../pages/MobileAssistantPage/MobileAssistantPage';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';

export default function Root() {
  const locale = useLocale();

  return (
    <ThemeProvider
      theme={{
        ...ConsumerLightTheme,
        locale,
      }}
    >
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/me/home" element={<HomePage />}></Route>
          <Route path="/me/discover" element={<DiscoverPage />}></Route>
          <Route path="/me/bundle/start" element={<LoadingPage />}></Route>
          <Route
            path="/me/mobile/assistant"
            element={<MobileAssistantPage />}
          ></Route>
          <Route path="/me/auth" element={<LoadingPage />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
