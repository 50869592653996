// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptyContent__Z5niL{margin-top:174px}@media screen and (max-width:540px){.emptyContent__Z5niL{margin-top:74px}}`, "",{"version":3,"sources":["webpack://./src/pages/DiscoverPage/DiscoverPage.css"],"names":[],"mappings":"AAAA,qBACE,gBACF,CAEA,oCACE,qBACE,eACF,CACF","sourcesContent":[".emptyContent {\n  margin-top: 174px;\n}\n\n@media screen and (max-width: 540px) {\n  .emptyContent {\n    margin-top: 74px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyContent": `emptyContent__Z5niL`
};
export default ___CSS_LOADER_EXPORT___;
