import {Box, useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Grid';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {ContentCard} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/discover_service';
import ErrorToast from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorToast/ErrorToast';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import useInterval from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useInterval';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AsyncAssistantLoader} from '../../components/AsyncAssistantLoader/AsyncAssistantLoader';
import EducationFeed from '../../components/EducationFeed/EducationFeed';
import DiscoverService from '../../services/grpc/DiscoverService';
import logError from '../../utils/logError';

import './DiscoverPage.css';

const LIST_CONTENT_CARDS_INTERVAL_MS = 5000;

function DiscoverPage() {
  const {t} = useTranslation();
  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('discover-page-header')}`;
  });

  const [contentCards, setContentCards] = useState<ContentCard[]>([]);
  const [isFeedLoading, setIsFeedLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [initialLoadFailed, setInitialLoadFailed] = useState<boolean>(false);

  useEffect(() => {
    listContentCards(true);
  }, []);

  // Fetch every 5 seconds after page load
  useInterval(listContentCards, LIST_CONTENT_CARDS_INTERVAL_MS);

  async function listContentCards(initialLoad?: boolean) {
    const {cached, fresh} = DiscoverService.listContentCards();
    if (initialLoad && !cached) setIsFeedLoading(true);
    // Use the cached Content Cards on the initial load, if available
    if (initialLoad && cached) updateContentCards(cached);
    // Update state to use actual Content Cards whenever available
    fresh.then(updateContentCards).catch(err => {
      logError(err);
      setErrorMessage(err.message);

      if (!isAuthenticationError(err)) {
        // Render the error page if the initial load fails
        if (initialLoad) setInitialLoadFailed(true);
        setErrorToast(true);
        setIsFeedLoading(false);
      }
    });
  }

  const updateContentCards = (contentCards: ContentCard[]) => {
    setContentCards(contentCards);
    // Render the main page after we successfully fetch data
    setInitialLoadFailed(false);
    setIsFeedLoading(false);
  };

  return (
    <Layout
      header={
        <Header headerText={t('discover-page-header')} hasBackButton={false} />
      }
    >
      <DiscoverPageContent
        isFeedLoading={isFeedLoading}
        contentCards={contentCards}
        initialLoadFailed={initialLoadFailed}
      />
      <ErrorToast
        userFriendlyText={t('unknown-error-occurred')}
        errorMessage={errorMessage}
        open={errorToast}
        onClose={() => setErrorToast(false)}
      />
    </Layout>
  );
}

const DiscoverPageContent = ({
  isFeedLoading,
  contentCards,
  initialLoadFailed,
}) => {
  const {t} = useTranslation();
  const isLargeScreen = useMediaQuery('(min-width:1201px)');

  const assistantEnabled = config.getBoolean('FEATURE_ASSISTANT_ENABLED');

  if (initialLoadFailed) {
    return (
      <Grid container justifyContent="center">
        <ErrorView
          title={t('something-went-wrong')}
          subtitle={t('were-having-an-issue')}
          refreshText={t('refresh')}
        />
      </Grid>
    );
  }

  return (
    <Box
      display={'flex'}
      flexDirection={isLargeScreen ? 'row' : 'column-reverse'}
      gap={5}
    >
      <EducationFeed isLoading={isFeedLoading} contentCards={contentCards} />
      {assistantEnabled && <AsyncAssistantLoader />}
    </Box>
  );
};

export default DiscoverPage;
