import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {
  Card,
  ListTodoCardsRequest,
} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/card_service';
import {CardServiceClient} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/card_service.client';
import {createSessionCache} from '@verily-src/verily1-verily-me-web-common-typescript/src/services/CachedEndpoint';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {handleGrpcError} from './ErrorHandling';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class CardService {
  private cardServiceClient;
  private listTodoCardsCache = createSessionCache<Card[]>(() =>
    this._listTodoCards()
  );

  async getClient() {
    if (!this.cardServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.cardServiceClient = new CardServiceClient(transport);
    }

    return this.cardServiceClient;
  }

  listTodoCards = this.listTodoCardsCache.get;

  private async _listTodoCards(): Promise<Card[]> {
    // TODO: Remove language_tag from ListTodoCardsRequest
    const request: ListTodoCardsRequest = {languageTag: ''};

    const cardServiceClient = await this.getClient();

    try {
      return (await cardServiceClient.listTodoCards(request)).response.cards;
    } catch (err) {
      throw handleGrpcError(err);
    }
  }
}

export default new CardService();
