const styles = {
  supportingText: {
    display: 'flex',
  },
  source: {
    display: 'flex',
    gap: '8px',
    flex: '1 0 0',
  },
  brandIcon: {
    display: 'flex',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    width: '44px',
    height: '44px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stateLayer: {
    display: 'flex',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.75',
    backgroundColor: '#181817',
    borderRadius: '50%',
  },
  topElements: {
    display: 'flex',
    width: '100%',
    padding: '8px 8px 0px 0px',
    justifyContent: 'flex-end',
    zIndex: '10',
    position: 'absolute',
  },
  openInNewIcon: {
    color: '#FFFFFF',
    width: '18px',
    height: '18px',
  },
  sourceText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 0',
  },
} as const;

export default styles;
