import {config} from '@verily-src/phaf-runtime-helpers';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ConversationalAssistantPage from '../../pages/ConversationalAssistantPage/ConversationalAssistantPage';
import DiscoverPage from '../../pages/DiscoverPage/DiscoverPage';
import HomePage from '../../pages/HomePage/HomePage';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import MobileAssistantPage from '../../pages/MobileAssistantPage/MobileAssistantPage';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';

export default function Root() {
  const isDarkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={isDarkModeEnabled}>
      <BrowserRouter>
        <Routes>
          <Route path="/me/home" element={<HomePage />}></Route>
          <Route path="/me/discover" element={<DiscoverPage />}></Route>
          <Route path="/me/bundle/start" element={<LoadingPage />}></Route>
          {/* TODO: Move assistant routes to Assistant MFE */}
          <Route
            path="/me/mobile/assistant"
            element={<MobileAssistantPage />}
          ></Route>
          {/* TODO: Remove test route when conversational assistant is live.
          It is here for demo purposes on dev-stable and the component is feature-flagged */}
          <Route
            path="/me/mobile/conversational-assistant"
            element={<ConversationalAssistantPage />}
          ></Route>
          <Route path="/me/auth" element={<LoadingPage />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </VerilyMeThemeProvider>
  );
}
